import React, { useContext, useEffect, useState } from "react";
import { getAllProducts, getAllTitleDesc } from "../api";
import Editor from "../components/Editor";
import { serverURL, updateTemplate } from "../product";
import { StoreProvider } from "./ContextProvider";
import Spinner from "./Spinner";
import FileManagerModal from "./FileManager/FileManagerModal";

const EditTemplate = ({ product }) => {
  const { setAllProduct, token } = useContext(StoreProvider);
  const [allTags, setAllTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [existingData, setExistingData] = useState({});
  const [shortDetail, setShortDetail] = useState("");
  const [description, setDescription] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [response, setResponse] = useState({ isError: false, message: "" });
  const [zipFiles, setZipFiles] = useState({});
  const [licenseFiles, setLicenseFiles] = useState({});
  const [fileStatus, setFileStatus] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAdd = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResponse({ isError: false, message: "" });
    const templatesData = {
      ...existingData,
      short_detail: shortDetail,
      detail: description,
      price: JSON.stringify(existingData?.price),
      tags: JSON.stringify(selectedTags),
    };
    if (zipFiles?.free || templatesData?.github_free_file_repo_url) {
      templatesData.price = JSON.stringify({
        ...existingData?.price,
        free: "0",
      });
    }

    templatesData.download_file = JSON.stringify({
      ...templatesData?.download_file,
      ...zipFiles,
    });

    templatesData.download_file_license = JSON.stringify({
      ...templatesData?.download_file_license,
      ...licenseFiles,
    });

    const add = await updateTemplate(templatesData, token);
    setLoading(false);
    setResponse(add);
    if (!response?.isError) {
      const newProducts = await getAllProducts(token);
      setAllProduct(newProducts?.products);
    }
  };

  const loadAllTags = async () => {
    const tags = await getAllTitleDesc(token);
    if (!tags?.isError) {
      setAllTags(tags?.data);
    }
  };

  const handleSelectTag = (e) => {
    if (e.target.value) {
      const tag = JSON.parse(e.target.value);
      const tagIds = selectedTags?.map((item) => item?.id);
      if (tag?.id && !tagIds.includes(tag?.id)) {
        setSelectedTags([...selectedTags, tag]);
      }
    }
  };

  const handleFileManagerModal = (type, status) => {
    setIsModalOpen(true);
    setFileStatus({ type, status });
  };

  const handleSelectPreviewFile = (selectedFile) => {
    if (fileStatus?.type === "template") {
      const newFile = {
        ...zipFiles,
        [fileStatus.status]: selectedFile?.filename,
      };
      setZipFiles(newFile);
    } else if (fileStatus?.type === "license") {
      const newLicenseFile = {
        ...licenseFiles,
        [fileStatus.status]: selectedFile?.filename,
      };
      setLicenseFiles(newLicenseFile);
    } else if (fileStatus?.type === "featuredImage") {
      setExistingData({ ...existingData, image: selectedFile?.filename });
    }
  };

  const removeTag = (id) => {
    const existingTag = selectedTags.filter((item) => item?.id !== id);
    setSelectedTags(existingTag);
  };

  useEffect(() => {
    setExistingData(product);
    if (product?.tags) {
      setSelectedTags(product?.tags);
    }
    setShortDetail(product?.short_detail);
    setDescription(product?.detail);
  }, [product]);

  useEffect(() => {
    loadAllTags();
  }, []);

  return (
    <section className="py-24">
      <div style={{ marginBottom: "150px" }}>
        <div className="px-4 xl:container mx-auto sm:px-4">
          <div className="-mx-4 sm:d-flex flex-wrap lg:flex-no-wrap">
            <div className="mb-5 w-full">
              <div className="mb-3 mt-3">
                <h2 className="mb-2 font-heading text-4xl font-bold tracking-tighter text-black">
                  Update Templates
                </h2>
              </div>
              <form onSubmit={handleAdd}>
                <div className="d-flex justify-between">
                  <div className="w-50 pe-5">
                    <label htmlFor="name">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Name"
                      name="name"
                      id="name"
                      defaultValue={existingData?.name}
                      onInput={(e) =>
                        setExistingData({
                          ...existingData,
                          name: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <div className="w-50 pe-5">
                    <label htmlFor="slug">
                      Slug <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Slug"
                      name="slug"
                      id="slug"
                      defaultValue={existingData?.slug}
                      onInput={(e) =>
                        setExistingData({
                          ...existingData,
                          slug: e.target.value
                            ?.toString()
                            .trim()
                            .replace(/\s+/g, "-")
                            .toLowerCase(),
                        })
                      }
                      required
                    />
                  </div>
                </div>

                <div className="mt-5 d-flex justify-between">
                  <div className="w-50 pe-5">
                    <label htmlFor="built_with">Built with</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="example: Tailwind"
                      name="built_with"
                      id="built_with"
                      defaultValue={existingData?.built_with}
                      onInput={(e) =>
                        setExistingData({
                          ...existingData,
                          built_with: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <div className="w-50 pe-5">
                    <label htmlFor="version">Version</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="example: 1.1.1"
                      name="version"
                      id="version"
                      defaultValue={existingData?.current_version}
                      onInput={(e) =>
                        setExistingData({
                          ...existingData,
                          current_version: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                </div>

                <div className="mt-5 d-flex justify-between">
                  <div className="w-50 pe-5">
                    <label htmlFor="preview-link">Preview Link</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="example: https://example.com"
                      name="preview-link"
                      id="preview-link"
                      defaultValue={existingData?.preview_link}
                      onInput={(e) =>
                        setExistingData({
                          ...existingData,
                          preview_link: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <div className="w-50 pe-5">
                    <label htmlFor="featured">Is Featured</label>
                    <select
                      id="featured"
                      className="form-control"
                      name="is_featured"
                      defaultValue={existingData?.is_featured}
                      onChange={(e) =>
                        setExistingData({
                          ...existingData,
                          is_featured: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                    </select>
                  </div>
                </div>

                <div className="mt-5 d-flex justify-between">
                  <div className="w-50 pe-5">
                    <label htmlFor="default-variation">Default Variation</label>
                    <select
                      id="default-variation"
                      className="form-control"
                      value={existingData?.default_variation}
                      onInput={(e) =>
                        setExistingData({
                          ...existingData,
                          default_variation: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="free">Free</option>
                      <option value="starter">Starter</option>
                      <option value="business">Business</option>
                      <option value="extended">Extended</option>
                    </select>
                  </div>
                  <div className="w-50 pe-5">
                    <div>
                      <p>Featured Image: {existingData?.image}</p>
                      <button
                        type="button"
                        onClick={() =>
                          handleFileManagerModal(
                            "featuredImage",
                            "featuredImage"
                          )
                        }
                        className="btn btn-primary ms-auto"
                      >
                        Select
                      </button>
                    </div>
                    <div className="mt-5">
                      {existingData?.image && (
                        <img
                          src={`${serverURL}/image/${existingData?.image}`}
                          alt=""
                          style={{ width: 600 }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {/* Tags div */}
                <div className="mt-3 d-flex justify-between">
                  <div className="w-50 pe-5">
                    <label htmlFor="default-variation">Select Tags</label>
                    <select
                      id="default-variation"
                      className="form-control"
                      onInput={handleSelectTag}
                      required={selectedTags?.length === 0 ? true : false}
                    >
                      <option defaultValue={true} value="">
                        select
                      </option>
                      {allTags?.map((tag) => (
                        <option
                          key={tag?.id}
                          value={JSON.stringify({
                            id: tag?.id,
                            name: tag?.tag_name,
                            slug: tag?.slug || "",
                          })}
                        >
                          {tag?.tag_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-50 pe-5">
                    <h5>Tags</h5>
                    {selectedTags?.map((item) => (
                      <span
                        key={item?.id}
                        className="block rounded bg-primary text-light p-1 pe-0 me-2 mb-2"
                      >
                        {item?.name}
                        <span
                          onClick={() => removeTag(item?.id)}
                          title="delete"
                          style={{ cursor: "pointer" }}
                          className="rounded-circle ms-2 bg-danger p-1"
                        >
                          x
                        </span>
                      </span>
                    ))}
                  </div>
                </div>

                <div className="w-50 mt-5 mb-5 pe-5">
                  <label htmlFor="github_repo_url">Github Repository URL</label>
                  <input
                    className="form-control mt-2"
                    type="text"
                    placeholder="Github Repository URL"
                    name="github_repo_url"
                    id="github_repo_url"
                    defaultValue={existingData?.github_repo_url}
                    onInput={(e) =>
                      setExistingData({
                        ...existingData,
                        github_repo_url: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="w-50 mt-5 mb-5 pe-5">
                  <label htmlFor="github_free_file_repo_url">
                    Github Free File Repository URL
                  </label>
                  <input
                    className="form-control mt-2"
                    type="text"
                    placeholder="Github Free File Repository URL"
                    name="github_free_file_repo_url"
                    id="github_free_file_repo_url"
                    defaultValue={existingData?.github_free_file_repo_url}
                    onInput={(e) =>
                      setExistingData({
                        ...existingData,
                        github_free_file_repo_url: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="w-50 mt-5 mb-5 pe-5">
                  <label htmlFor="doc_url">Documentation URL</label>
                  <input
                    className="form-control mt-2"
                    type="text"
                    placeholder="Documentation Url"
                    name="doc_url"
                    id="doc_url"
                    defaultValue={existingData?.doc_url}
                    onInput={(e) =>
                      setExistingData({
                        ...existingData,
                        doc_url: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="w-50 mt-5 mb-5 pe-5">
                  <label htmlFor="free_variation_text">
                    Free Variation Text
                  </label>
                  <input
                    className="form-control mt-2"
                    type="text"
                    placeholder="Free Variation Text"
                    name="free_variation_text"
                    id="free_variation_text"
                    defaultValue={existingData?.free_variation_text}
                    onInput={(e) =>
                      setExistingData({
                        ...existingData,
                        free_variation_text: e.target.value,
                      })
                    }
                  />
                </div>

                <h2 className="mt-8 text-base font-medium">Price</h2>
                <div className="mt-3 d-flex justify-between">
                  <div className="w-25 pe-5">
                    <label htmlFor="starter-price">Starter</label>
                    <input
                      className="form-control"
                      type="number"
                      placeholder="example: 100"
                      name="starter-price"
                      id="starter-price"
                      defaultValue={existingData?.price?.starter}
                      onInput={(e) =>
                        setExistingData({
                          ...existingData,
                          price: {
                            ...existingData?.price,
                            starter: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                  <div className="w-25 pe-5">
                    <label htmlFor="business-price">Business</label>
                    <input
                      className="form-control"
                      type="number"
                      placeholder="example: 200"
                      name="business-price"
                      id="business-price"
                      defaultValue={existingData?.price?.business}
                      onInput={(e) =>
                        setExistingData({
                          ...existingData,
                          price: {
                            ...existingData?.price,
                            business: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                  <div className="w-25 pe-5">
                    <label htmlFor="extended-price">Extended</label>
                    <input
                      className="form-control"
                      type="number"
                      placeholder="example: 500"
                      name="extended-price"
                      id="extended-price"
                      defaultValue={existingData?.price?.extended}
                      onInput={(e) =>
                        setExistingData({
                          ...existingData,
                          price: {
                            ...existingData?.price,
                            extended: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>

                {/* previous Product File Area  */}
                <div className="mt-5 d-flex justify-between">
                  <div className="w-50 pe-5">
                    <h2 className="mb-2 text-base font-medium">
                      Previous Product File
                    </h2>
                    <p className="mb-3">Previous Products File</p>
                    <p>
                      <strong>Free: </strong>
                      {existingData?.download_file?.free}
                    </p>
                  </div>

                  <div className="mt-5 w-50 pe-5">
                    <p className="mb-3">Previous Product License</p>
                    <p>
                      <strong>Free: </strong>
                      {existingData?.download_file_license?.free}
                    </p>
                  </div>
                </div>

                <div className="mt-4 d-flex justify-between">
                  <div className="w-50 pe-5">
                    <p>
                      <strong>Starter: </strong>
                      {existingData?.download_file?.starter}
                    </p>
                  </div>

                  <div className="w-50 pe-5">
                    <p>
                      <strong>Starter: </strong>
                      {existingData?.download_file_license?.starter}
                    </p>
                  </div>
                </div>

                <div className="mt-4 d-flex justify-between">
                  <div className="w-50 pe-5">
                    <p>
                      <strong>Business: </strong>
                      {existingData?.download_file?.business}
                    </p>
                  </div>

                  <div className="w-50 pe-5">
                    <p>
                      <strong>Business: </strong>
                      {existingData?.download_file_license?.business}
                    </p>
                  </div>
                </div>

                <div className="mt-4 d-flex justify-between">
                  <div className="w-50 pe-5">
                    <p>
                      <strong>Extended: </strong>
                      {existingData?.download_file?.extended}
                    </p>
                  </div>

                  <div className="w-50 pe-5">
                    <p>
                      <strong>Extended: </strong>
                      {existingData?.download_file_license?.extended}
                    </p>
                  </div>
                </div>

                {/* Product File Area */}
                <h2 className="mt-5 text-base font-medium">
                  Product File Area
                </h2>

                <div className="mt-5 d-flex justify-content-around">
                  {/* product zip filename */}
                  <div className="w-50 me-5">
                    <h5 className="mb-4 mt-5">Template zip file</h5>
                    <div className="mb-4 d-flex">
                      <p>Free version: {zipFiles?.free}</p>
                      <button
                        type="button"
                        onClick={() =>
                          handleFileManagerModal("template", "free")
                        }
                        className="btn btn-primary ms-auto"
                      >
                        Select
                      </button>
                    </div>
                    <div className="mb-4 d-flex">
                      <p>Starter version: {zipFiles?.starter}</p>
                      <button
                        type="button"
                        onClick={() =>
                          handleFileManagerModal("template", "starter")
                        }
                        className="btn btn-primary ms-auto"
                      >
                        Select
                      </button>
                    </div>
                    <div className="mb-4 d-flex">
                      <p>Business version: {zipFiles?.business}</p>
                      <button
                        type="button"
                        onClick={() =>
                          handleFileManagerModal("template", "business")
                        }
                        className="btn btn-primary ms-auto"
                      >
                        Select
                      </button>
                    </div>
                    <div className="mb-4 d-flex">
                      <p>Extended version: {zipFiles?.extended}</p>
                      <button
                        type="button"
                        onClick={() =>
                          handleFileManagerModal("template", "extended")
                        }
                        className="btn btn-primary ms-auto"
                      >
                        Select
                      </button>
                    </div>
                  </div>
                  {/* product txt filename */}
                  <div className="w-50 ms-5">
                    <h5 className="mb-4 mt-5">Template license file</h5>
                    <div className="mb-4 d-flex">
                      <p>Free version: {licenseFiles?.free}</p>
                      <button
                        type="button"
                        onClick={() =>
                          handleFileManagerModal("license", "free")
                        }
                        className="btn btn-primary ms-auto"
                      >
                        Select
                      </button>
                    </div>
                    <div className="mb-4 d-flex">
                      <p>Starter version: {licenseFiles?.starter}</p>
                      <button
                        type="button"
                        onClick={() =>
                          handleFileManagerModal("license", "starter")
                        }
                        className="btn btn-primary ms-auto"
                      >
                        Select
                      </button>
                    </div>
                    <div className="mb-4 d-flex">
                      <p>Business version: {licenseFiles?.business}</p>
                      <button
                        type="button"
                        onClick={() =>
                          handleFileManagerModal("license", "business")
                        }
                        className="btn btn-primary ms-auto"
                      >
                        Select
                      </button>
                    </div>
                    <div className="mb-4 d-flex">
                      <p>Extended version: {licenseFiles?.extended}</p>
                      <button
                        type="button"
                        onClick={() =>
                          handleFileManagerModal("license", "extended")
                        }
                        className="btn btn-primary ms-auto"
                      >
                        Select
                      </button>
                    </div>
                  </div>
                </div>

                <Editor
                  description={description}
                  shortDetail={shortDetail}
                  setShortDetail={setShortDetail}
                  setDescription={setDescription}
                />

                <div className="mt-5 mb-5 text-center">
                  {response?.message && (
                    <p
                      style={{ color: response?.isError ? "red" : "green" }}
                      className="mb-4"
                    >
                      {response?.message}
                    </p>
                  )}
                </div>

                <div className="mt-5 d-flex items-center justify-center">
                  <button
                    type="submit"
                    disabled={loading ? true : false}
                    style={{ cursor: loading ? "not-allowed" : "pointer" }}
                    className="d-flex items-center btn btn-primary ms-auto me-auto"
                  >
                    Update Template {loading && <Spinner />}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <FileManagerModal
        isSelect={true}
        openModal={isModalOpen}
        handleSelectPreviewFile={handleSelectPreviewFile}
        setOpenModal={setIsModalOpen}
      />
    </section>
  );
};

export default EditTemplate;
