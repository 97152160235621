import ImageUploader from "quill-image-uploader";
import React from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { imageHandler } from "../api";

Quill.register("modules/imageUploader", ImageUploader);

const modules = {
  toolbar: [
    [{ font: ["inter"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image"],
    ["clean"],
  ],
  imageUploader: {
    upload: imageHandler,
  },
};

const Editor = (props) => {
  const { shortDetail, setShortDetail, description, setDescription } = props;
  const handleEditor = (content, delta, source, editor) => {
    setDescription(editor.getHTML());
    setShortDetail(
      editor
        ?.getText()
        ?.slice(0, 140)
        ?.replace(/\s{2,}/g, " ")
        ?.trim()
    );
  };

  return (
    <div>
      {/* editor */}
      <div className="mt-5 mb-5 text-center">
        <h1 className="mt-5 font-heading text-3xl font-bold text-black">
          {"<"} Here is Editor {"/>"}
        </h1>
      </div>
      <div className="">
        <div className="mb-4 w-full">
          <label htmlFor="shortDetail" className="fw-bold fs-5 form-label">
            Short Detail{" "}
            <span>
              {shortDetail?.length > 0 ? shortDetail?.length : "0"} /140
            </span>
          </label>
          <textarea
            style={{ resize: "none" }}
            type="text"
            className="form-control"
            id="shortDetail"
            placeholder="Short Detail"
            rows="4"
            maxLength={140}
            defaultValue={shortDetail}
          />
        </div>
      </div>
      <h4>Detail</h4>
      <ReactQuill
        modules={modules}
        theme="snow"
        value={description}
        onChange={handleEditor}
      />
      <div className="mt-5 mb-5 text-center">
        <p className="text-danger mb-4">
          Please check again all information provided are correct
        </p>
      </div>
    </div>
  );
};

export default Editor;
