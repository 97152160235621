import axios from "axios";

export const serverURL = process.env.REACT_APP_SERVER_CONNECT;
export const clientURL = process.env.REACT_APP_CLIENT_URL;
export const S3ImagePublicBucketUrl = process.env.REACT_APP_IMAGE_BUCKET_LINK;

const client = axios.create({
  baseURL: serverURL,
  headers: { originUrl: clientURL },
});

client.interceptors.response.use(
  ({ data }) => data,
  (error) => Promise.reject(error)
);

export const addProduct = async (data, encrypted_token) => {
  try {
    const result = await client.post("/admin/product/addProduct", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        accessToken: `Bearer ${encrypted_token}`,
      },
    });
    return result;
  } catch (error) {
    console.log(error);
    return {
      isError: true,
      error,
      message: "There is some problem. Please try again.",
    };
  }
};

export const getSingleProductBySlug = async (slug, encrypted_token) => {
  try {
    const result = await client.get(
      `/admin/product/single-product-by-slug/${slug}`,
      {
        headers: {
          accessToken: `Bearer ${encrypted_token}`,
        },
      }
    );
    return result;
  } catch (error) {
    return {
      isError: true,
      error,
      message: "There is some problem. Please try again.",
    };
  }
};

export const updateTemplate = async (data, encrypted_token) => {
  try {
    const result = await client.patch("/admin/product/update", data, {
      headers: {
        accessToken: `Bearer ${encrypted_token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return result;
  } catch (error) {
    return {
      isError: true,
      error,
      message: "There is some problem. Please try again.",
    };
  }
};

export const removeTemplate = async (id, encrypted_token) => {
  try {
    const removed = await client.delete(`/admin/product/remove/${id}`, {
      headers: {
        accessToken: `Bearer ${encrypted_token}`,
      },
    });
    return removed;
  } catch (error) {
    return {
      isError: true,
      error,
      message: "There is a server Error",
    };
  }
};
