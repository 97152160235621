import React, { useContext, useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { StoreProvider } from "../ContextProvider";
import FileSection from "./FileSection";
import UploadImageSection from "./UploadImageSection";
import { getAllFiles } from "../../api";

const FileManagerModal = (props) => {
  const {
    openModal,
    setOpenModal,
    isSelect = false,
    handleSelectPreviewFile = function () { },
  } = props;
  const { token } = useContext(StoreProvider);
  const [type, setType] = useState("image");
  const [filteredFile, setFilteredFile] = useState([]);
  const [previewFile, setPreviewFile] = useState({});
  const [isUploadSectionOpen, setIsUploadSectionOpen] = useState(false);

  const onCloseModal = () => setOpenModal(false);

  const handleTypeChange = (currentType) => {
    setType(currentType);
  };

  const loadFiles = async (currentType) => {
    if (currentType) {
      const result = await getAllFiles(currentType, token);
      if (result?.files) {
        setFilteredFile(result?.files);
      }
    }
  };

  const handleSearch = (e) => {
    if (e?.target?.value) {
      const match = filteredFile?.filter((file) =>
        file?.filename
          ?.toString()
          ?.toLowerCase()
          ?.includes(e?.target?.value?.toString()?.toLowerCase())
      );
      setFilteredFile(match);
    } else {
      loadFiles(type)
    }
  };

  return (
    <div>
      <Modal
        open={openModal}
        onClose={onCloseModal}
        center
        classNames={{
          modal: "customModal",
        }}
      >
        <div className="container flex-grow-1 light-style container-p-y mt-5">
          <div className="container-m-nx container-m-ny bg-lightest mb-3">
            <hr className="m-0" />
            <div className="container-p-x py-2">
              <div className="d-flex">
                <div>
                  <button
                    onClick={() => {
                      setIsUploadSectionOpen(true);
                      setPreviewFile(null);
                    }}
                    className="btn btn-primary me-3"
                  >
                    Upload
                  </button>
                  <button
                    onClick={() => {
                      setIsUploadSectionOpen(false);
                      setPreviewFile(null);
                    }}
                    className="btn btn-info me-2"
                  >
                    Files
                  </button>
                  {!isUploadSectionOpen && (
                    <div className="d-inline">
                      &gt;
                      <select
                        onChange={(e) => handleTypeChange(e.target.value)}
                        className="p-1"
                      >
                        <option value="image">Images</option>
                        <option value="template">Templates</option>
                      </select>
                    </div>
                  )}
                </div>
                <div className="ms-auto">
                  <input
                    onInput={handleSearch}
                    type="search"
                    className="form-control"
                    placeholder="Search"
                  />
                </div>
              </div>
            </div>
            <hr className="m-0" />
          </div>

          {isUploadSectionOpen ? (
            <UploadImageSection type={type} loadFiles={loadFiles} />
          ) : (
            <FileSection
              type={type}
              isSelect={isSelect}
              loadFiles={loadFiles}
              previewFile={previewFile}
              filteredFile={filteredFile}
              setOpenModal={setOpenModal}
              setPreviewFile={setPreviewFile}
              handleSelectPreviewFile={handleSelectPreviewFile}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default FileManagerModal;
