export const serverUrl = process.env.REACT_APP_SERVER_CONNECT;
export const clientURL = process.env.REACT_APP_CLIENT_URL;

export async function sendDataToServer(product, url, method, token) {
  return await fetch(url, {
    method: method,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      enctype: "multipart/form-data",
      accessToken: `Bearer ${token}`,
      originUrl: clientURL,
    },
    body: product,
  }).then((res) => res.json());
}

// export async function addProduct(product, token) {
//   const url = `${serverUrl}/admin/product/addProduct`;
//   return await sendDataToServer(product, url, "POST", token);
// }

// export async function editProduct(product, token) {
//   const url = `${serverUrl}/admin/product/update`;
//   return await sendDataToServer(product, url, "PATCH", token);
// }

async function getMethod(url, token) {
  return await fetch(url, {
    method: "GET",
    headers: {
      accessToken: `Bearer ${token}`,
      originUrl: clientURL,
    },
  }).then((res) => res.json());
}

export async function getProduct(productId, token) {
  return await getMethod(`${serverUrl}/admin/product/${productId}`, token);
}

export async function getAllProducts(token) {
  return await getMethod(`${serverUrl}/admin/product`, token);
}

export async function login(data) {
  return await fetch(`${serverUrl}/admin/auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      originUrl: clientURL,
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());
}

export async function checkAdmin(token) {
  return await fetch(`${serverUrl}/admin/auth/check_admin`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      accessToken: `Bearer ${token}`,
      originUrl: clientURL,
    },
    body: JSON.stringify({ a: 1 }),
  }).then((res) => res.json());
}

// export async function deleteProduct(pId, token) {
//   return await fetch(`${serverUrl}/admin/product/remove/${pId}`, {
//     method: "DELETE",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//       accessToken: `Bearer ${token}`,
//       originUrl: clientURL,
//     },
//   }).then((res) => res.json());
// }

export async function getAllTags(token) {
  return await getMethod(`${serverUrl}/products/all_tags`, token);
}

export async function addTitleDesc(data, token) {
  const url = `${serverUrl}/admin/title_desc/add`;
  return await sendDataToServer(data, url, "POST", token);
}

export async function getAllTitleDesc(token) {
  return await getMethod(`${serverUrl}/products/title_desc`, token);
}

export async function getTitleDesc(id, token) {
  return await getMethod(`${serverUrl}/admin/title_desc/${id}`, token);
}

export async function updateTitleDesc(data, token) {
  const url = `${serverUrl}/admin/title_desc/update`;
  return await sendDataToServer(data, url, "PATCH", token);
}

export async function deleteTitleDesc(id, token) {
  return await fetch(`${serverUrl}/admin/title_desc/delete/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      accessToken: `Bearer ${token}`,
      originUrl: clientURL,
    },
  }).then((res) => res.json());
}

export async function getAllAccessPrice() {
  return await getMethod(`${serverUrl}/extra/all_price`, "");
}

export async function updateAllAccessPrice(info, token) {
  const url = `${serverUrl}/extra/all_price/update`;
  return await sendDataToServer(info, url, "PATCH", token);
}

export async function getAllDiscount(token) {
  return await getMethod(`${serverUrl}/extra/discount`, token);
}

export async function addDiscount(discountInfo, token) {
  return await sendDataToServer(
    discountInfo,
    `${serverUrl}/extra/discount/add`,
    "POST",
    token
  );
}

export async function deleteDiscount(name, token) {
  return await sendDataToServer(
    {},
    `${serverUrl}/extra/discount/delete/${name}`,
    "DELETE",
    token
  );
}

export async function imageHandler(file) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("image", file);
    fetch(`${serverUrl}/extra/quill-body/image`, {
      method: "POST",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        enctype: "multipart/form-data",
        originUrl: clientURL,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        if (!result?.isError) {
          resolve(result.data.url);
        } else {
          reject("Upload Failed. There is a server error");
        }
      })
      .catch((error) => {
        reject("Upload failed");
        console.error("Error:", error);
      });
  });
}

export async function getUsers(id = "", token) {
  return await getMethod(`${serverUrl}/admin/control/user/${id}`, token);
}

export async function updateUser(info, token) {
  return await sendDataToServer(
    info,
    `${serverUrl}/admin/control/user/update-info`,
    "PATCH",
    token
  );
}

export async function updateUserPassword(info, token) {
  return await sendDataToServer(
    info,
    `${serverUrl}/admin/control/user/update-password`,
    "PATCH",
    token
  );
}

export function generateCookie(encrypted_token, days) {
  if (days > 0) {
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + days);
    return `__accessToken__=${encrypted_token}; expires=${expireDate.toUTCString()}; path=/`;
  } else {
    return "__accessToken__=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }
}

export function getCookie() {
  let name = "__accessToken__=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export async function getUserTransaction(email, token) {
  return await getMethod(
    `${serverUrl}/admin/control/user/transact/${email}`,
    token
  );
}

export async function getAllFiles(category, token) {
  return await getMethod(`${serverUrl}/extra/fileManager/files/${category}`, token);
}

export const uploadFile = async (data, token) => {
  return await sendDataToServer(data, `${serverUrl}/extra/fileManager/upload`, "POST", token);
};

export async function removeFile(category, filename, token) {
  return await fetch(`${serverUrl}/extra/fileManager/remove/${category}/${filename}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      accessToken: `Bearer ${token}`,
      originUrl: clientURL,
    },
  }).then((res) => res.json());
}

export async function purgeCache(token) {
  try {
    return await fetch(`${serverUrl}/admin/clear-cache`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        accessToken: `Bearer ${token}`,
        originUrl: clientURL,
      },
    }).then((res) => res.json())
  } catch (error) {
    return { isError: true, message: error.message }
  }
}