import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { generateCookie } from "../api";
import { StoreProvider } from "./ContextProvider";

const Nav = () => {
  const { admin, setAdmin, setAllProduct } = useContext(StoreProvider);

  const handleLogout = () => {
    document.cookie = generateCookie("", 0);
    setAdmin({});
    setAllProduct([]);
  };
  return (
    <nav
      style={{ backgroundColor: "#5285ec" }}
      className="navbar navbar-expand-lg navbar-dark"
    >
      <div className="container container-fluid">
        <Link className="navbar-brand text-light" to="/">
          Home
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto fw-bold mb-2 mb-lg-0">
            {admin?.isVerified && (
              <>
                <li className="nav-item fw-normal me-2">
                  <Link className="nav-link text-light" to="/">
                    Products
                  </Link>
                </li>
                {/* <li className="nav-item fw-normal">
                  <select
                    style={{ outline: "none" }}
                    className="nav-link border-0 text-light bg-primary"
                    onChange={(e) =>
                      e.target.value && navigate(`/file/${e.target.value}`)
                    }
                  >
                    <option value="">Files</option>
                    <option value="images">Images</option>
                    <option value="templates">Templates</option>
                    <option value="license">License</option>
                    <option value="output">Output</option>
                  </select>
                </li> */}
                <li className="nav-item fw-normal ms-3">
                  <Link className="nav-link text-light" to="/filemanager">
                    Files
                  </Link>
                </li>
                <li className="nav-item fw-normal ms-3">
                  <Link className="nav-link text-light" to="/all-user">
                    Users
                  </Link>
                </li>
                <li className="nav-item fw-normal ms-3">
                  <Link className="nav-link text-light" to="/discount">
                    Discount
                  </Link>
                </li>
                <li className="nav-item fw-normal ms-3">
                  <Link className="nav-link text-light" to="/all-access-price">
                    All Access Price
                  </Link>
                </li>
                <li className="nav-item fw-normal ms-3">
                  <Link className="nav-link text-light" to="/tag-title">
                    Tag Title
                  </Link>
                </li>
                <li className="nav-item fw-normal ms-3">
                  <Link className="nav-link text-light" to="/addProduct">
                    Add Product
                  </Link>
                </li>

                <li className="nav-item fw-normal ms-3">
                  <Link className="nav-link text-light" to="/operations">
                    Operations
                  </Link>
                </li>
              </>
            )}
            <li className="nav-item fw-normal">
              {admin?.isVerified ? (
                <span
                  onClick={handleLogout}
                  style={{ cursor: "pointer" }}
                  className="nav-link text-light"
                >
                  Logout
                </span>
              ) : (
                <Link className="nav-link text-light" to="/login">
                  Login
                </Link>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
