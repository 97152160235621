import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { StoreProvider } from "./components/ContextProvider";
import PrivateRoute from "./components/PrivateRoute.js";
import AddProductPage from "./page/AddProductPage";
import AddTagTitlePage from "./page/AddTagTitlePage";
import AllAccessPrice from "./page/AllAccessPrice";
import AllUsers from "./page/AllUsers";
import Discount from "./page/Discount";
import EditProductPage from "./page/EditProductPage";
import EditTagTitlePage from "./page/EditTagTitlePage";
import EditUserPage from "./page/EditUserPage";
import FilePage from "./page/FilePage";
import Home from "./page/Home";
import Login from "./page/Login";
import NotFound from "./page/NotFound";
import OperationsPage from "./page/OperationsPage.js";
import ProductDetailPage from "./page/ProductDetailPage";
import TagTitlePage from "./page/TagTitlePage";
import TransactionPage from "./page/TransactionPage";
import UserDetailPage from "./page/UserDetailPage";
import "./styles/global.scss";

function App() {
  const { admin } = useContext(StoreProvider);
  return (
    <div>
      <Routes>
        <Route
          path="/login"
          element={admin?.isVerified ? <Navigate to="/" /> : <Login />}
        />
        <Route path="/" element={<PrivateRoute children={<Home />} />} />
        <Route
          path="/addProduct"
          element={<PrivateRoute children={<AddProductPage />} />}
        />
        <Route
          path="/edit/:product_id"
          element={<PrivateRoute children={<EditProductPage />} />}
        />
        <Route
          path="/detail/:product_id"
          element={<PrivateRoute children={<ProductDetailPage />} />}
        />
        <Route
          path="/tag-title"
          element={<PrivateRoute children={<TagTitlePage />} />}
        />
        <Route
          path="/tag-title/add"
          element={<PrivateRoute children={<AddTagTitlePage />} />}
        />
        <Route
          path="/tag-title/edit/:id"
          element={<PrivateRoute children={<EditTagTitlePage />} />}
        />
        <Route
          path="/all-access-price"
          element={<PrivateRoute children={<AllAccessPrice />} />}
        />
        <Route
          path="/discount"
          element={<PrivateRoute children={<Discount />} />}
        />
        <Route
          path="/all-user"
          element={<PrivateRoute children={<AllUsers />} />}
        />
        <Route
          path="/user/detail/:user_id"
          element={<PrivateRoute children={<UserDetailPage />} />}
        />
        <Route
          path="/user/edit/:user_id"
          element={<PrivateRoute children={<EditUserPage />} />}
        />
        <Route
          path="/filemanager"
          element={<PrivateRoute children={<FilePage />} />}
        />
        <Route
          path="/user/transaction/:user_id"
          element={<PrivateRoute children={<TransactionPage />} />}
        />
        <Route
          path="/operations"
          element={<PrivateRoute children={<OperationsPage />} />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
