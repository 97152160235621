import React from "react";
import { Link } from "react-router-dom";
import { serverURL } from "../product";

const UserDetail = ({ user, allAccess }) => {
  return (
    <div>
      <h2 className="card-title mt-3 mb-4">
        User Detail
        <Link title="Edit user" to={`/user/edit/${user?.id}`}>
          <button className="btn btn-warning ms-5 ps-4 pe-4">Edit User</button>
        </Link>
        <Link title="Transaction" to={`/user/transaction/${user?.id}`}>
          <button className="btn btn-success ms-5 ps-4 pe-4">
            Transaction
          </button>
        </Link>
        <Link title="All User" to={`/all-user`}>
          <button className="btn btn-info ms-5 ps-4 pe-4">All User</button>
        </Link>
      </h2>
      <div style={{ fontSize: "20px" }}>
        <div className="mb-1 d-flex w-75">
          <p style={{ width: "200px" }}>Email</p>
          <p>
            : <b className="ms-3">{user?.email}</b>
          </p>
        </div>
        <div className="mb-1 d-flex w-75">
          <p style={{ width: "200px" }}>Full Name</p>
          <p>
            : <b className="ms-3">{user?.name}</b>
          </p>
        </div>
        <div className="mb-1 d-flex w-75">
          <p style={{ width: "200px" }}>First Name</p>
          <p>
            : <b className="ms-3">{user?.first_name}</b>
          </p>
        </div>
        <div className="mb-1 d-flex w-75">
          <p style={{ width: "200px" }}>Last Name</p>
          <p>
            : <b className="ms-3">{user?.last_name}</b>
          </p>
        </div>
        <div className="mb-1 d-flex w-75">
          <p style={{ width: "200px" }}>Company Name</p>
          <p>
            : <b className="ms-3">{user?.company_name}</b>
          </p>
        </div>
        <div className="mb-1 d-flex w-75">
          <p style={{ width: "200px" }}>Location</p>
          <p>
            : <b className="ms-3">{user?.location}</b>
          </p>
        </div>
        <div className="mb-1 d-flex w-75">
          <p style={{ width: "200px" }}>Total Product</p>
          <p>
            :{" "}
            <b className="ms-3">
              {allAccess?.product_id ? "All Access" : user?.my_products?.length}
            </b>
          </p>
        </div>
        <h3>User Product:</h3>
        <div className="d-flex" style={{ flexWrap: "wrap" }}>
          {allAccess?.product_id ? (
            <div
              className="card ms-1 me-1 mt-5 mb-3"
              style={{ width: "19rem" }}
            >
              <img
                className="card-img-top"
                src={`https://i.ibb.co/4ZHyLrH/all-access-pass.jpg`}
                alt="all Access"
              />
              <div className="card-body">
                <h5 title={allAccess?.name} className="card-title">
                  {allAccess?.name?.slice(0, 40)}...
                </h5>
                <p className="mt-4 mb-0">
                  Variant:{" "}
                  <b>
                    {allAccess?.status === "extend"
                      ? "Extended"
                      : allAccess?.status?.slice(0, 1).toUpperCase() +
                      allAccess?.status?.slice(1, allAccess?.status?.length)}
                  </b>
                </p>
                <p className="mt-0 mb-0">
                  Price: <b>${allAccess?.price}</b>
                </p>
                <p className="mt-0 mb-0">
                  Date: <b>{allAccess?.date}</b>
                </p>
              </div>
            </div>
          ) : (
            user?.my_products?.length > 0 &&
            user?.my_products.map((product) => (
              <div
                key={product?.product_id}
                className="card ms-1 me-1 mt-5 mb-3"
                style={{ width: "19rem" }}
              >
                <img
                  className="card-img-top"
                  src={`${serverURL}/image/${product?.image}`}
                  alt={product?.name?.slice(0, 15)}
                />
                <div className="card-body">
                  <h5 title={product?.name} className="card-title">
                    {product?.name?.slice(0, 40)}...
                  </h5>
                  <p className="mt-4 mb-0">
                    Variant:{" "}
                    <b>
                      {product?.status === "extend"
                        ? "Extended"
                        : product?.status?.slice(0, 1).toUpperCase() +
                        product?.status?.slice(1, product?.status?.length)}
                    </b>
                  </p>
                  <p className="mt-0 mb-0">
                    Price: <b>${product?.price}</b>
                  </p>
                  <p className="mt-0 mb-0">
                    Date: <b>{product?.date}</b>
                  </p>
                  <div className="mt-4 d-flex justify-content-around">
                    <Link title="detail" to={`/detail/${product?.product_id}`}>
                      <button className="btn btn-info me-5 ps-3 pe-3">
                        Detail
                      </button>
                    </Link>
                    {/* <Link title="detail" to={`/edit/${product?.product_id}`}>
											<button className="btn btn-warning ps-4 pe-4">
												Edit
											</button>
										</Link> */}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
