import React from 'react';
import IndexSearch from '../components/IndexSearch';
import Layout from '../components/Layout';
import ServerCache from '../components/ServerCache';

const OperationsPage = () => {
    return (
        <Layout>
            <IndexSearch />
            <ServerCache />
        </Layout>
    );
};

export default OperationsPage;