import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { deleteTitleDesc, getAllTitleDesc } from "../api";
import { StoreProvider } from "../components/ContextProvider";
import Layout from "../components/Layout";

const TagTitlePage = () => {
	const { token } = useContext(StoreProvider);
	const [data, setData] = useState([]);
	const handleLoad = async () => {
		const response = await getAllTitleDesc(token);
		if (!response?.isError) {
			setData(response?.data);
		}
	};

	const handleDelete = async (id) => {
		const confirm = window.confirm("Are you sure to delete this Title");
		if (confirm) {
			const deleteResponse = await deleteTitleDesc(id, token);
			if (!deleteResponse?.isError) {
				handleLoad();
			}
		}
	};

	useState(() => {
		handleLoad();
	}, []);
	return (
		<Layout>
			<div>
				<h2 className="card-title mt-3 mb-3">Title and Description for Tag</h2>
				<Link to="/tag-title/add">
					<button className="btn btn-info ps-4 pe-4">Add</button>
				</Link>
				<div style={{ flexWrap: "wrap" }} className="d-flex">
					{data.map((item) => (
						<div
							key={item?.tag_name}
							className="card bg-light m-3"
							style={{ minWidth: "18rem", maxWidth: "18rem" }}
						>
							<img
								style={{ height: "150px", objectFit: "cover" }}
								className="card-img-top"
								src={item?.image_link}
								alt={item?.tag_name}
							/>
							<div className="card-header">{item?.tag_name}</div>
							<div className="card-body">
								<h5 className="card-title">{item?.title}</h5>
								<p className="card-text">
									{item?.description.slice(0, 150)}...
								</p>
							</div>
							<div className="d-flex ps-4 pe-4 pb-2">
								<button
									onClick={() => handleDelete(item?.id)}
									title="delete"
									className="btn btn-danger ps-3 pe-3"
								>
									Delete
								</button>
								<Link
									className="ms-auto"
									to={`/tag-title/edit/${item?.id}`}
								>
									<button className="btn btn-warning ps-4 pe-4">Edit</button>
								</Link>
							</div>
						</div>
					))}
				</div>
			</div>
		</Layout>
	);
};

export default TagTitlePage;
