import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addTitleDesc } from "../api";
import { StoreProvider } from "../components/ContextProvider";
import Layout from "../components/Layout";
import Spinner from "../components/Spinner";

import ImageUploader from "quill-image-uploader";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { imageHandler } from "../api";

Quill.register("modules/imageUploader", ImageUploader);

const modules = {
  toolbar: [
    [{ font: ["inter"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image"],
    ["clean"],
  ],
  imageUploader: {
    upload: imageHandler,
  },
};

const AddTagTitlePage = () => {
  const { token } = useContext(StoreProvider);
  const [tag, setTag] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(null);

  const navigate = useNavigate();

  const handleEditor = (editor) => {
    setDescription(editor);
  };

  const handleChange = (e) => {
    if (e.target.value) {
      setTag({ ...tag, [e.target.name]: e.target.value });
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const { tag_name, slug, title, image_link, description } = tag;
    if (tag_name && slug) {
      setLoading(true);
      const formData = new FormData();
      formData.append("tag_name", tag_name);
      formData.append("slug", slug);
      formData.append("title", title || "");
      formData.append("image_link", image_link || "");
      formData.append("description", description || "");
      formData.append("seo_title", tag?.seo_title || "");
      formData.append("seo_description", tag?.seo_description || "");
      const response = await addTitleDesc(formData, token);
      setLoading(false);
      if (!response?.isError) {
        navigate("/tag-title");
      }
      if (response?.isError && response?.error?.code === "ER_DUP_ENTRY") {
        setError(response?.error?.sqlMessage);
      } else if (
        response?.isError &&
        response?.error?.code !== "ER_DUP_ENTRY"
      ) {
        setError(response?.message);
      }
    } else {
      alert("Please fill all field");
    }
  };

  return (
    <Layout>
      <div>
        <h2 className="card-title mt-3 mb-3">Add Tag</h2>
        <form onSubmit={handleSave}>
          {error?.length > 0 && <p className="text-danger">{error}</p>}
          <div className="d-flex align-items-center">
            <h5 className="me-5">Name:</h5>
            <input
              className="w-75 mb-4 p-2 ms-4"
              type="text"
              name="tag_name"
              placeholder="Software"
              onChange={handleChange}
              required
            />
          </div>
          <div className="d-flex align-items-center">
            <h5 className="me-5">Slug:</h5>
            <input
              className="w-75  p-2  ms-5"
              type="text"
              name="slug"
              placeholder="software"
              onChange={handleChange}
              required
            />
          </div>
          <div className="mt-4 d-flex align-items-center">
            <h5 className="me-5">Title:</h5>
            <input
              className="w-75  p-2  ms-5"
              type="text"
              name="title"
              placeholder="Title"
              onChange={handleChange}
            />
          </div>
          <div className="mt-4 d-flex align-items-center">
            <h5 className="me-4">Image Link:</h5>
            <input
              className="w-75 p-2 ms-2"
              type="text"
              name="image_link"
              placeholder="Image Link"
              onChange={handleChange}
            />
          </div>
          <div className="mt-4 d-flex">
            <h5>Description: </h5>
            {/* <textarea
              className="w-75  p-2  ms-4"
              type="text"
              name="description"
              placeholder="Description"
              rows={6}
              onChange={handleChange}
            /> */}

            <div
              className="w-75 p-2  ms-4"
              style={{ backgroundColor: "white" }}
            >
              <ReactQuill
                modules={modules}
                theme="snow"
                value={description}
                onChange={handleEditor}
              />
            </div>
          </div>

          <div className="mt-4 d-flex align-items-center">
            <h5 className="me-5">SEO Title:</h5>
            <input
              className="w-75  p-2  ms-5"
              type="text"
              name="seo_title"
              placeholder="SEO Title"
              onInput={handleChange}
            />
          </div>

          <div className="mt-4 d-flex">
            <h5>SEO Description: </h5>
            <textarea
              className="w-75  p-2  ms-4"
              type="text"
              name="seo_description"
              placeholder="SEO Description"
              rows={6}
              onInput={handleChange}
            />
          </div>
          <div className="text-center mt-5 mb-5">
            <button
              type="submit"
              disabled={loading ? true : false}
              className="btn btn-primary ps-5 pe-5"
            >
              Save {loading && <Spinner />}
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default AddTagTitlePage;
