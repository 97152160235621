import React, { useContext, useState } from "react";
import { purgeCache } from "../api";
import { StoreProvider } from "./ContextProvider";
import Spinner from "./Spinner";

const ServerCache = () => {
  const { token } = useContext(StoreProvider);
  const [purging, setPurging] = useState(false);

  const handlePurgeCache = async () => {
    setPurging(true);
    const response = await purgeCache(token);
    setPurging(false);
    if (!response?.isError) {
      alert("Cache Successfully Purged");
    }
  };

  return (
    <div className="mt-5 border p-4">
      <h2 className="mb-2 font-heading text-4xl font-bold tracking-tighter text-black">
        Click the Purge button to clear the server cache
      </h2>
      <button
        style={{ cursor: purging ? "not-allowed" : "pointer" }}
        disabled={purging ? true : false}
        onClick={() => handlePurgeCache()}
        className="mt-3 btn btn-primary ms-auto me-auto"
      >
        Purge Cache {purging && <Spinner />}
      </button>
    </div>
  );
};

export default ServerCache;
