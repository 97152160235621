import React, { useState } from "react";
import Layout from "../components/Layout";
import FileManagerModal from "../components/FileManager/FileManagerModal";


const FilePage = () => {
  const [openModal, setOpenModal] = useState(true);

  return (
    <Layout>
      <FileManagerModal openModal={openModal} setOpenModal={setOpenModal} />
    </Layout>
  );
};

export default FilePage;
